import style from "./progress.module.css";
import { useEffect, useState } from "react";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export default function Progress({ progress }) {
  const [percentage, setPercentage] = useState(50);

  useEffect(() => {
    console.log(progress);
  }, [progress]);

  return (
    typeof progress == "object" && (
      <div className={style.container}>
        <div className={style.bar_container}>
          <div
            className={style.bar}
            style={{ width: `${progress ? progress.progress.toFixed(1) : 0}%` }}
          />
        </div>
        <div className={style.footer}>
          <div className={style.percentage}>
            {progress ? progress.progress.toFixed(1) : 0}%
          </div>
          <div className={style.progress}>
            {formatBytes(progress ? progress.rate : 0)}/s
          </div>
        </div>
      </div>
    )
  );
}
