import style from "./update_application.module.css";
import AddForm from "./AddForm";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "config";
import Updates from "./Updates";

export default function Index() {
  const [products, setProducts] = useState([]);
  const fetchProducts = () => {
    const url = `${config.api_host}/products`;
    axios
      .get(url)
      .then(({ data }) => {
        if (data.error) {
          window.alert(data.message);
        } else {
          setProducts(data.data);
        }
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const [selectedProduct, setSelectedProduct] = useState(undefined);

  return (
    <div className={style.container}>
      <div>~ Add New Update</div>
      <AddForm products={products} onAdded={fetchProducts} />
      <hr />
      <div>~ Update List</div>
      <div className={style.product_toggle_lists}>
        {products.map((product, i) => (
          <span
            key={i}
            className={style.product_item}
            onClick={() => setSelectedProduct(product)}
            active={selectedProduct == product ? "1" : "0"}
          >
            {product}
          </span>
        ))}
      </div>

      <Updates product={selectedProduct} onDeleted={fetchProducts} />
    </div>
  );
}
