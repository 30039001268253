import style from "./add_form.module.css";
import { useEffect, useState } from "react";
import Note from "./Note";
import Notes from "./Notes";
import Progress from "./Progress";
import axios from "axios";
import config from "config";
import FormData from "form-data";

export default function AddForm({ products = [], onAdded }) {
  const [productName, setProductName] = useState("");
  const [buildNumber, setBuildNumber] = useState("");
  const [buildVersion, setBuildVersion] = useState("");
  const [file, setFile] = useState(undefined);
  const [notes, setNotes] = useState([]);

  const onNotesChange = (notes) => setNotes(notes);
  const handleChangeProductName = (e) => setProductName(e.target.value);
  const handleChangeBuildNumber = (e) => setBuildNumber(e.target.value);
  const handleChangeBuildVersion = (e) => setBuildVersion(e.target.value);
  const handleChangeFile = (e) => {
    const files = e.target.files;
    if (files[0]) {
      setFile(files[0]);
    } else {
      setFile(undefined);
    }
  };

  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(undefined);

  useEffect(() => {
    if (progress) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
  }, [progress]);

  const submit = () => {
    const url = `${config.api_host}/update/submit`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("productName", productName);
    formData.append("buildNumber", buildNumber);
    formData.append("buildNumberStr", buildVersion);
    formData.append("notes", JSON.stringify(notes));

    axios
      .post(url, formData, {
        headers: formData.getHeaders,
        onUploadProgress: (prog) => setProgress(prog),
      })
      .then(({ data }) => {
        window.alert(data.message);
        setProgress(undefined);
        if (!data.error) {
          onAdded();
        }
      })
      .catch((err) => {
        setProgress(undefined);
        window.alert(err.message);
      });
  };

  return (
    <div className={style.container}>
      <input
        disabled={processing}
        type="text"
        placeholder="Product Name"
        value={productName}
        onChange={handleChangeProductName}
      />
      <input
        type="number"
        disabled={processing}
        placeholder="Build Number"
        value={buildNumber}
        onChange={handleChangeBuildNumber}
      />
      <input
        disabled={processing}
        type="text"
        placeholder="Build Version String"
        value={buildVersion}
        onChange={handleChangeBuildVersion}
      />
      <input type="file" onChange={handleChangeFile} disabled={processing} />
      <Note notes={notes} onChange={onNotesChange} disabled={processing} />
      <Notes notes={notes} onChange={onNotesChange} disabled={processing} />
      {/* {!processing && <Progress />} */}
      <Progress progress={progress} />
      <button
        onClick={submit}
        disabled={
          processing ||
          notes.length < 1 ||
          file == undefined ||
          buildVersion.length < 1 ||
          isNaN(buildNumber) ||
          buildNumber.length < 1 ||
          productName.length < 1
        }
      >
        Submit Update
      </button>
    </div>
  );
}
