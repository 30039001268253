import style from "./notes.module.css";

export default function Notes({ notes, onChange, disabled }) {
  const deleteList = (i) => {
    var x = [...notes];
    x.splice(i, 1);
    onChange(x);
  };

  return (
    notes.length > 0 && (
      <ul className={style.list}>
        {notes.map((note, index) => (
          <li key={index}>
            {note.type} - {note.note}{" "}
            <button onClick={() => deleteList(index)} disabled={disabled}>
              Hapus
            </button>
          </li>
        ))}
      </ul>
    )
  );
}
